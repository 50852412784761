<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        
        <!--  BEGIN CONTENT PART  -->
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 {{ $t('myinventreq.centerinv')}}
                    
                    div
                       nav.breadcrumb-one(aria-label="breadcrumb")
                          ol.breadcrumb
                              li.breadcrumb-item
                                a(href="javascript:void(0);") {{ $t('headers.dashboard')}}
                              li.breadcrumb-item
                                a(href="javascript:void(0);") {{ $t('headers.centers')}}
                              li.breadcrumb-item.active(aria-current="page") {{ $t('myinventreq.myinv')}}

                div.row.mt-5.pb-5
                    available-inventory-component
                    pending-request-component

</template>

<script>
/* eslint-disable */
import PendingRequestComponent from '@/components/Admin/PendingRequestComponent'
import AvailableInventoryComponent from '@/components/Admin/AvailableInventoryComponent'
export default {
    name: 'MyInventoryRequest',
    components: {
        PendingRequestComponent, AvailableInventoryComponent
    },
    data() {
        return {
        }
    },
    methods: {
    },
    mounted() {

    }
}
</script>
