<template lang="pug">
    div.col-lg-6.col-md-6.layout-spacing
        div.statbox.widget.box.box-shadow
            div.widget-header
                div.row
                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                        h4
                            svg.feather.feather-clipboard(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                path(d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2")
                                rect(x="8" y="2" width="8" height="4" rx="1" ry="1")
                            template {{ $t('comp.admpedrecomp')}}

            div.widget-content.widget-content-area
                div.table-responsive.mt-5
                    table.table.table-bordered.table-hover.mb-4
                        thead
                            tr
                                th {{ $t('comp.admcenter2')}}
                                th {{ $t('comp.admrequestcent1')}}  
                                th {{ $t('comp.admpedrecomp1')}}
                                th {{ $t('comp.admrequestcent3')}}
                        tbody
                            
                            tr(v-for="centerP in pendingRequest")
                                td {{ centerP.sCenterName }}
                                td {{ centerP.sCenterName}}
                                td {{ centerP.iRequestInventory }}                                        
                                td {{ centerP.dRequestDate }}         

</template>
<script>
/* eslint-disable */
export default {
    name: 'AvailableInventoryComponent',
    data() {
        return {
            pendingRequest: [],
        }
    },
    methods: {
        async getPendingInventory() {
            await axios.get(apiURL + 'Center/getListMyInventoryPendind?idPerson='+ localStorage.CertIdP, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.pendingRequest = response.data.centersList
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        }
        
    },
    mounted() {
        this.getPendingInventory()
    }
        

}
</script>                                                              